<template>
  <v-card>
    <v-card-title class="d-flex" style="justify-content: space-between">
      <div>Sport by year</div>
      <v-select
          v-model="selectedSport"
          :items="sports"
          item-text="name"
          item-value="sport_id"
          label="Sport"
          dense
          outlined
          hide-details="auto"
          style="max-width: 200px"
        ></v-select>
    </v-card-title>
    <vue-apex-charts
      ref="refChart"
      :options="chartOptions"
      :series="chartData"
    />
  </v-card>
</template>

<script>
import {
  mdiDotsVertical,
} from '@mdi/js'
import { ref, onUnmounted, computed, watch } from '@vue/composition-api'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
  },
  setup() {

    const $vuetify = getVuetify()

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const monthName = index => {
      return moment.monthsShort(index - 1)
    }

    const params = []
    const stats = ref({})
    const selectedSport = ref(9)
    const sports = ref([])

    store
      .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchSports`)
      .then(response => {
        sports.value = response.data

      })
      .catch(error => {
        // console.error(error)
        // console.error(error.response)
      })

    const refChart = ref(null)

    const fetchStats = (params) => {

      store
        .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchStats`,params)
        .then(response => {

          stats.value = response.data

          chartData.length = 0
          chartOptions.colors.length = 0
          chartOptions.xaxis.categories.length = 0

          chartData.push({
            name: "",
            data: []
          })

          for (var i = 0; i < stats.value.stats.length; i++) {

            chartOptions.colors.push(stats.value.sport.label_color)
            chartOptions.labels.push(stats.value.stats[i].year)
            chartOptions.xaxis.categories.push(stats.value.stats[i].year)

            chartData[0].name = stats.value.sport.name
            chartData[0].data.push(stats.value.stats[i].count)

          }

          refChart.value.updateSeries(chartData)
          refChart.value.updateOptions(chartOptions)


        })
        .catch(error => {
          console.error(error)
          console.error(error.response)
        })
    }

    const chartOptions = {
      colors: [],
      labels: [],
      chart: {
        type: 'bar',
        height: 400,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
          distributed: true,
          borderRadius: 4,
          startingShape: 'rounded',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontWeight: 'bold',
        }
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {

      },
      grid: {
        show: false
      },
    }

    const chartData = []

    fetchStats({
      sport: selectedSport.value,
      type: "workouts"
    })

    watch(selectedSport, () => {
      fetchStats({
        sport: selectedSport.value,
        type: "workouts"
      })
    })

    return {
      chartOptions,
      chartData,
      refChart,
      sports,
      selectedSport,
      icons: {
        mdiDotsVertical
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
