<template>
  <v-card>
    <v-card-title class="d-flex">
      Locations in {{ currentYearLocal }}
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="on"
            class="ml-auto"
          >
            <v-icon size="18">
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group v-model="currentYearLocal">
            <v-list-item
              v-for="(year,i) in yearList"
              :key="year"
              :value="year"
            >
              <v-list-item-title>
                <span>{{ year }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-row>
      <v-col cols="12" md="3" v-for="group in locations">
        <v-card flat>
          <v-card-subtitle>
            <span class="badge-sm" :style="`background-color: ${group.sport.label_color}`"></span>
            <strong>{{ group.sport.name }}</strong>
          </v-card-subtitle>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>LOCATION</th>
                  <th class="text-right">COUNT</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="loc in group.locations">
                  <td>
                    {{ loc.location }}
                  </td>
                  <td align="right">{{ loc.count }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  mdiDotsVertical,
} from '@mdi/js'
import { ref, onUnmounted, computed, watch } from '@vue/composition-api'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'

export default {
  props: {
    yearList: {
      type: Array,
      required: true
    },
    currentYear: {
      type: String,
      required: true
    }
  },
  setup(props, { emit } ) {

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const locations = ref({})

    const currentYearLocal = ref(props.currentYear)
    const fetchStats = (params) => {

      store
        .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchStats`,params)
        .then(response => {

          if (response.data == "") {
            locations.value = []
          } else {
            locations.value = response.data
          }


        })
        .catch(error => {
          console.error(error)
          console.error(error.response)
        })
    }

    fetchStats({
      year : props.currentYear,
      type : "locations"
    })

    watch(
      () => props.currentYear,
      () => {
        currentYearLocal.value = props.currentYear
        fetchStats({
          year: props.currentYear,
          type: "locations"
        })
      },
      { deep: true },
    )

    watch(currentYearLocal, () => {
      fetchStats({
        year: currentYearLocal.value,
        type: "locations"
      })
      emit('update-current-year',currentYearLocal.value)
    })

    return {
      locations,
      currentYearLocal,
      icons: {
        mdiDotsVertical
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
    vertical-align: -2px;
  }
</style>
